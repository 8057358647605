import type { ErrorIdent } from '@/@types/errorIdents';
import { useSecureSessionDelete } from '~/composables/dataFetching/genericFetchers';
import useDeleteVerificationFileDialog from '~/composables/dialogs/useDeleteVerificationFileDialog';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useDeleteVerificationFile() {
  const dialog = useDeleteVerificationFileDialog();
  const siteIdent = useSiteIdent();
  const errors = ref<ErrorIdent[]>([]);
  const isLoading = ref(false);

  const file = dialog.getData();

  if (!file?.id) dialog.close();

  async function deleteFile() {
    try {
      isLoading.value = true;

      if (file) {
        await useSecureSessionDelete(
          `/api/${siteIdent}/user/verficationFile/${file.id}`,
        );
        dialog.close();
      }

      isLoading.value = false;
    } catch (e: any) {
      errors.value = e.data.data.errors;
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  return {
    errors,
    dialog,
    isLoading,
    deleteFile,
  };
}
